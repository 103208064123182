import * as React from "react";
import { AppBar } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import Logo from '../assets/Logotype_blanc_corail_1024.png';

import {dev} from '../config';

const useStyles = makeStyles()((theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  appBar: {
    backgroundColor: dev ? '#638596' : '#FF5151',
  },
  logo: {
    marginTop: 5,
    marginBottom: 5,
    height: 35,
  }
}));

const MyAppBar = props => {
  const {classes} = useStyles();
  return (
    <AppBar {...props} className={classes.appBar}>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        <img src={Logo} className={classes.logo} alt="Logo" />
        <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
