import * as React from "react";

import Card from '@mui/material/Card';
import { makeStyles } from 'tss-react/mui';
import {List, Datagrid, TextField, Show, Tab, TabbedForm, FormTab} from 'react-admin';
import {EditButton, Edit, TextInput, FormDataConsumer} from 'react-admin';
import {TabbedShowLayout, NumberInput, ArrayInput, SimpleFormIterator} from 'react-admin';
import { useRecordContext } from 'react-admin';
import {ListComponent} from "./ListComponent";
import {AudioDirectionComponent} from "./TrackComponent";
import {CustomShowActions} from "./CustomComponents";

export const AudioDirectionList = props => {
  return (<List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="_id" label="Id"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

// --------------------------------- Show ---------------------------------

export const AudioDirectionShow = (props) => {
  return (<Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="_id" label="Name"/>
      </Tab>
      <Tab label="assets">
        <AssetsList source="assets" label="Assets"/>
      </Tab>
      <Tab label="texts">
        <h4>Français</h4>
        <TextsList source="texts.fr" label="Texts"/>
        <h4>Anglais</h4>
        <TextsList source="texts.en" label="Texts"/>
      </Tab>
      <Tab label="sequence">
        <SequenceList source="sequence" label="Sequence"/>
      </Tab>
      <Tab label="message">
        <MessageList source="message" label="Message"/>
      </Tab>
    </TabbedShowLayout>
  </Show>)
};

const AssetsList = ({ source }) => {
  const record = useRecordContext();
  const fields = ['Asset', 'Variable', 'Url'];
  const data = [];
  Object.keys(record[source]).map(asset => {
    if (typeof record[source][asset] === 'string') {
      data.push({
        key: asset,
        Asset: asset,
        Variable: null,
        Url: record[source][asset],
      });
    } else {
      Object.keys(record[source][asset]).map(subfield => {
        data.push({
          key: asset + record[source][asset][subfield],
          Asset: asset,
          Variable: subfield,
          Url: record[source][asset][subfield],
        });
        return null;
      })
    }
    return null;
  })
  return <ListComponent fields={fields} data={data}/>;
};

const TextsList = ({ source }) => {
  const record = useRecordContext();
  const fields = ['Variable', 'Text'];
  const lang = source.split('.')[1];
  const classes = useStyles();
  if (!record || !record.texts || !record.texts[lang]) {
    return null;
  }
  const texts = record.texts[lang];
  return (
    <>
      <Card fullWidth={true} className={classes.card}>
        {Object.keys(texts).map(asset => {
          const data = [];
          if (typeof texts[asset] === 'string') {
            data.push({
              key: asset,
              Variable: asset,
              Text: texts[asset],
            });
          } else if (typeof texts[asset] === 'object' && texts[asset].length) {
            texts[asset].map(text => {
              data.push({
                key: asset + text,
                Variable: asset,
                Text: text,
              });
              return null;
            })
          } else if (typeof texts[asset] === 'object') {
            Object.keys(texts[asset]).map(variable => {
              if (typeof texts[asset][variable] === 'string') {
                data.push({
                  key: variable,
                  Variable: variable,
                  Text: texts[asset][variable],
                });
              } else if (typeof texts[asset][variable] === 'object' && texts[asset][variable].length) {
                texts[asset][variable].map(text => {
                  data.push({
                    key: variable + text,
                    Variable: variable,
                    Text: text,
                  });
                  return null;
                })
              } else {
                Object.keys(texts[asset][variable]).map(subvariable => {
                  if (typeof texts[asset][variable][subvariable] === 'string') {
                    data.push({
                      key: variable + ' - ' + subvariable,
                      Variable: variable + ' - ' + subvariable,
                      Text: texts[asset][variable][subvariable],
                    });
                  } else {
                    texts[asset][variable][subvariable].map(text => {
                      data.push({
                        key: variable + ' - ' + subvariable + ' ' + text,
                        Variable: variable + ' - ' + subvariable,
                        Text: text,
                      });
                      return null;
                    })
                  }
                  return null;
                })
              }
              return null;
            })
          }
          return (
            <>
              <h3>{capitalize(asset)}</h3>
              <ListComponent fields={fields} data={data} />
            </>
            );
          })
        }
      </Card>
    </>
  )

};

const SequenceList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <>
      <Card fullWidth={true} className={classes.card}>
        <AudioDirectionComponent audioDirection={record[source].background} title={'Background'} />
      </Card>
      <Card fullWidth={true} className={classes.card}>
        <AudioDirectionComponent audioDirection={record[source].intro} title={'Intro'} />
      </Card>
      <Card fullWidth={true} className={classes.card}>
        <AudioDirectionComponent audioDirection={record[source].outro} title={'Outro'} />
      </Card>
    </>
    );
};

const MessageList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <>
      {Object.keys(record[source]).map(key =>
        <>
          <Card fullWidth={true} className={classes.card}>
            <AudioDirectionComponent audioDirection={record[source][key]} title={capitalize(key)} />
          </Card>
        </>
      )}
    </>
  );
};

// --------------------------------- Edit ---------------------------------

export const AudioDirectionEdit = props => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <TabbedForm>

        {/* --- Assets --- */}
        <FormTab label="assets">
          <h2>Assets</h2>
          <JsonInput source='assets' />
        </FormTab>

        {/* --- Texts --- */}
        <FormTab label="texts">
          <h2>Texts français</h2>
          <JsonInput source='texts.fr' />
          <h2>Texts anglais</h2>
          <JsonInput source='texts.en' />
        </FormTab>

        {/* --- Sequence --- */}
        <FormTab label="sequence">
          <Card fullWidth={true} className={classes.card}>
            <h2>Sequence Background</h2>
            <h4>Background track</h4>
            <TrackInput source='sequence.background.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='sequence' lvl2='background' lvl3='parameters' />
          </Card>
          <Card fullWidth={true} className={classes.card}>
            <h2>Intro</h2>
            <h4>Track</h4>
            <TrackInput source='sequence.intro.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='sequence' lvl2='intro' lvl3='parameters' />
            <h4>Background track</h4>
            <TrackInput source='sequence.intro.backgroundTrack' />
            <h4>Background parameters</h4>
            <ParametersInput  lvl1='sequence' lvl2='intro' lvl3='backgroundParameters' />
          </Card>
          <Card fullWidth={true} className={classes.card}>
            <h2>Outro</h2>
            <h4>Track</h4>
            <TrackInput source='sequence.outro.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='sequence' lvl2='outro' lvl3='parameters' />
            <h4>Background track</h4>
            <TrackInput source='sequence.outro.backgroundTrack' />
            <h4>Background parameters</h4>
            <ParametersInput  lvl1='sequence' lvl2='outro' lvl3='backgroundParameters' />
          </Card>
        </FormTab>

        {/* --- Message --- */}
        <FormTab label="message">
          <Card fullWidth={true} className={classes.card}>
            <h3>Main</h3>
            <h4>Track</h4>
            <TrackInput source='message.main.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='message' lvl2='main' lvl3='parameters' />
            <h4>Background track</h4>
            <TrackInput source='message.main.backgroundTrack' />
            <h4>Background parameters</h4>
            <ParametersInput  lvl1='message' lvl2='main' lvl3='backgroundParameters' />
          </Card>

          <Card fullWidth={true} className={classes.card}>
            <h3>More</h3>
            <h4>Track</h4>
            <TrackInput source='message.more.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='message' lvl2='more' lvl3='parameters' />
            <h4>Background track</h4>
            <TrackInput source='message.more.backgroundTrack' />
            <h4>Background parameters</h4>
            <ParametersInput lvl1='message' lvl2='more' lvl3='backgroundParameters' />
          </Card>

          <Card fullWidth={true} className={classes.card}>
            <h3>More more</h3>
            <h4>Track</h4>
            <TrackInput source='message.more_more.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='message' lvl2='more_more' lvl3='parameters' />
            <h4>Background track</h4>
            <TrackInput source='message.more_more.backgroundTrack' />
            <h4>Background parameters</h4>
            <ParametersInput lvl1='message' lvl2='more_more' lvl3='backgroundParameters' />
          </Card>

          <Card fullWidth={true} className={classes.card}>
            <h3>Welcome welcome message</h3>
            <h4>Track</h4>
            <TrackInput source='message.mainWelcomeWelcome.track' />
            <h4>Parameters</h4>
            <ParametersInput lvl1='message' lvl2='mainWelcomeWelcome' lvl3='parameters' />
            <h4>Background track</h4>
            <TrackInput source='message.mainWelcomeWelcome.backgroundTrack' />
            <h4>Background parameters</h4>
            <ParametersInput lvl1='message' lvl2='mainWelcomeWelcome' lvl3='backgroundParameters' />
          </Card>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}

const JsonInput = props => {
  const classes = useStyles();
  return(
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const jsonInput = props.source.split('.').length === 1
          ? formData[props.source]
          :  formData[props.source.split('.')[0]][props.source.split('.')[1]];
        return (
          <>
            {Object.keys(jsonInput || {}).map(key => {
              if (typeof jsonInput[key] === 'string') {
                return (
                  <Card fullWidth={true} className={classes.card}>
                    <h4>{key}</h4>
                    <TextInput source={props.source + '.' + key} {...rest} fullWidth={true} />
                  </Card>
                )
              } else if (typeof jsonInput[key] === 'object' && jsonInput[key].length) {
                return (
                  <Card fullWidth={true} className={classes.card}>
                    <h4>{key}</h4>
                    <ArrayInput source={props.source + '.' + key} >
                      <SimpleFormIterator>
                        <TextInput fullWidth={true} />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Card>
                );
              } else if (typeof jsonInput[key] === 'object') {
                  return (
                    <Card fullWidth={true} className={classes.card}>
                      <h4>{key}</h4>
                      {Object.keys(jsonInput[key]).map(subKey => {
                        if (typeof jsonInput[key][subKey] === "string") {
                          return (
                            <>
                              <h5>{subKey}</h5>
                              <TextInput source={props.source + '.' + key + '.' + subKey} {...rest} label={subKey} fullWidth={true} />
                            </>
                          )
                        } else if (typeof jsonInput[key][subKey] === 'object' && jsonInput[key][subKey].length) {
                          return (
                            <>
                              <h5>{subKey}</h5>
                              <ArrayInput source={props.source + '.' + key + '.' + subKey} >
                                <SimpleFormIterator>
                                  <h6>{subKey}</h6>
                                  <TextInput label={subKey} fullWidth={true} />
                                </SimpleFormIterator>
                              </ArrayInput>
                            </>
                          )
                        } else {
                          return (
                            <>
                              {/*<h4>{subKey}</h4>*/}
                              {Object.keys(jsonInput[key][subKey]).map(subsubKey => {
                              if (typeof jsonInput[key][subKey][subsubKey] === "string") {
                                return (
                                  <>
                                    <h5>{subKey} - {subsubKey}</h5>
                                    <TextInput source={props.source + '.' + key + '.' + subKey + '.' +subsubKey} {...rest} label={subKey + '-' + subsubKey} fullWidth={true} />
                                  </>
                                )
                              } else {
                                return (
                                  <>
                                    <h5>{subKey} - {subsubKey}</h5>
                                    <ArrayInput source={props.source + '.' + key + '.' + subKey + '.' + subsubKey} label={''}>
                                      <SimpleFormIterator>
                                        <h6>{subKey} - {subsubKey}</h6>
                                        <TextInput label={subsubKey} fullWidth={true} />
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                  </>
                                )
                              }
                            })}
                            </>
                        )
                        }
                      })}
                    </Card>
                  );
              }
              return null;
            }
            )}
          </>
        );
      }}
    </FormDataConsumer>
  )
}

const ParametersInput = props => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        if (!formData[props.lvl1][props.lvl2]) {
          return null
        }
        const jsonInput = formData[props.lvl1][props.lvl2][props.lvl3];
        const source = props.lvl1 + '.' + props.lvl2 + '.' + props.lvl3;
        if (jsonInput === null) {
          return null
        }
        return (
          <>
            {Object.keys(jsonInput).map(param => {
              if (jsonInput[param] === null) {
                return null;
              }
              if (typeof jsonInput[param] !== 'object') {
                return (
                  <>
                    {param === 'volume' ?
                      <NumberInput label={param} source={source + '.' + param} {...rest} min={0} max={1} step={0.01}/> :
                      <TextInput label={param} source={source + '.' + param} {...rest} fullWidth={true} parse={parseStringInput}/>}
                  </>
                )
              } else if (jsonInput[param].length) {
                return (
                  // <Card fullWidth={true} className={classes.card}>
                  <>
                    <h4>{param}</h4>
                    <ArrayInput source={source + '.' + param} >
                      <SimpleFormIterator>
                        <TextInput fullWidth={true} />
                      </SimpleFormIterator>
                    </ArrayInput>
                  </>
                  // </Card>
                );
              } else {
                return Object.keys(jsonInput[param]).map(subkey => {
                  if (typeof jsonInput[param][subkey] === 'string') {
                    return (
                      <>
                        <h5>{param} {subkey}</h5>
                        <TextInput label={param} source={source + '.' + param + '.' + subkey} {...rest} fullWidth={true}
                                   parse={parseStringInput}/>
                      </>
                    )
                  } else if (typeof jsonInput[param][subkey] === 'object' && jsonInput[param][subkey].length) {
                    return (
                      <>
                        <h5>{param} {subkey}</h5>
                        <ArrayInput source={source + '.' + param + '.' + subkey} >
                          <SimpleFormIterator>
                            <TextInput fullWidth={true} />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </>
                    )
                  } else {
                    // Another object
                    return Object.keys(jsonInput[param][subkey]).map(subsubkey => {
                      if (typeof jsonInput[param][subkey][subsubkey] === 'string') {
                        return (
                          <>
                            <h5>{param} {subkey} {subsubkey}</h5>
                            <TextInput label={param}
                                       source={source + '.' + param + '.' + subkey + '.' + subsubkey} {...rest}
                                       fullWidth={true}
                                       parse={parseStringInput}/>
                          </>
                        )
                      } else if (typeof jsonInput[param][subkey][subsubkey] === 'object' && jsonInput[param][subkey][subsubkey].length) {
                        return (
                          <>
                            <h5>{param} {subkey} {subsubkey}</h5>
                            <ArrayInput source={source + '.' + param + '.' + subkey + '.' + subsubkey}>
                              <SimpleFormIterator>
                                <TextInput fullWidth={true}/>
                              </SimpleFormIterator>
                            </ArrayInput>
                          </>
                        )
                      }
                      return null;
                    });
                  }
                })
              }
            })
            }
          </>
        )
      }}
    </FormDataConsumer>
  )
}

const TrackInput = props => {
  return (
    <>
      <ArrayInput source={props.source} label={props.source.split('.').slice(-1)}>
        <SimpleFormIterator>
          <TextInput label={props.source.split('.').slice(-1) + ' item'} fullWidth={true} parse={parseStringInput}/>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

const capitalize = text => text[0].toUpperCase() + text.slice(1);
const digitsOnly = new RegExp('^[0-9]+$');
const parseStringInput = text => digitsOnly.test(text) ? parseInt(text) : text;

const useStyles = makeStyles()((theme) => ({
  card: {
    elevation: 3,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#f6f6f6',
  },
}));
