import React from 'react';
import {TopToolbar, EditButton, ListButton} from 'react-admin';
import {Toolbar, SaveButton} from 'react-admin';

export const CustomShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

export const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.disabled} />
  </Toolbar>
);
