import * as React from "react";
import {useState} from 'react';

import {List, Datagrid, TextField, Show, Tab, TabbedForm, FormTab} from 'react-admin';
import {EditButton, Edit, TabbedShowLayout, TextInput} from 'react-admin';
import {CustomShowActions, CustomToolbar} from "./CustomComponents";
import {JsonEditorInput, JsonEditorShow} from './JsonIDE';
import ButtonRow from "./ButtonRow";
import {EditorCheatSheet} from "./cheatSheets";

export const JungleCloudContextList = props => {
  return (<List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Id"/>
      <TextField source="minJuiceVersion" label="Version"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

// --------------------------------- Show ---------------------------------

export const JungleCloudContextShow = (props) => {
  const [collapsed, setCollapsed] = React.useState(1);
  return (<Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" label="Name"/>
        <TextField source="minJuiceVersion" label="Version"/>
      </Tab>
      <Tab label="Assets">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed}/>
        <JsonEditorShow source="assets" collapsed={collapsed} />
      </Tab>

      <Tab label="Journalists">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="journalists" collapsed={collapsed} />
      </Tab>

    </TabbedShowLayout>
  </Show>)
};

// --------------------------------- Edit ---------------------------------

export const JungleCloudContextEdit = props => {
  const [collapsed, setCollapsed] = React.useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Edit {...props}>
      <TabbedForm sanitizeEmptyValues={false} toolbar={<CustomToolbar disabled={isDisabled}/>}>

        <FormTab label="Summary">
          <TextField source="name" label="Name"/>
          <TextInput source="minJuiceVersion" label="Version"/>
        </FormTab>

        {/* --- Assets --- */}
        <FormTab label="Assets">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source="assets"  onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Journalists --- */}
        <FormTab label="Journalists">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source="journalists"  onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

      </TabbedForm>
      <EditorCheatSheet/>
    </Edit>
  );
}
