import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

import {db_api_url} from '../config';
// const httpClient = fetchUtils.fetchJson;

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${db_api_url}/${resource}/list?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => {
      return ({
          data: json.data.map(resource => ({ ...resource, id: resource._id }) ),
          total: json.total,
        })
      }
    );
  },

  getOne: (resource, params) =>{
    const url = `${db_api_url}/${resource}/getOne/${params.id.replace(/\//g, '%252F')}`;
    return httpClient(url).then(({json}) => {
      return ({
        data: {...json.data, id: json.data._id},
      })
    })
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${db_api_url}/${resource}/getList?${stringify(query)}`;
    console.log('Get many: ', url);
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  // getManyReference: (resource, params) => {
  //   const { page, perPage } = params.pagination;
  //   const { field, order } = params.sort;
  //   const query = {
  //     sort: JSON.stringify([field, order]),
  //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
  //     filter: JSON.stringify({
  //       ...params.filter,
  //       [params.target]: params.id,
  //     }),
  //   };
  //   const url = `${db_api_url}/${resource}?${stringify(query)}`;
  //
  //   return httpClient(url).then(({ headers, json }) => ({
  //     data: json,
  //     total: parseInt(headers.get('content-range').split('/').pop(), 10),
  //   }));
  // },
  update: (resource, params) => {
    const url = `${db_api_url}/${resource}/update/${params.id.replace(/\//g, '%252F')}`;
    // const url = `${db_api_url}/${resource}/update/${params.id.replace(/\//g, '%2F')}`;
    const body = params.data;
    if (resource === 'user') {
      delete body.activity;
    }
    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    }).then(({ json }) => ({
      data: {...json.data, id: json.data._id},
    }));
  },

  // updateMany: (resource, params) => {
  //   const query = {
  //     filter: JSON.stringify({ id: params.ids}),
  //   };
  //   return httpClient(`${db_api_url}/${resource}?${stringify(query)}`, {
  //     method: 'PUT',
  //     body: JSON.stringify(params.data),
  //   }).then(({ json }) => ({ data: json }));
  // },

  create: (resource, params) =>
    httpClient(`${db_api_url}/${resource}/create`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${db_api_url}/${resource}/delete/${params.id.replace(/\//g, '%2F')}`, {
      method: 'DELETE',
    }).then(({ json }) => ({
      data: json.data,
    })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids}),
    };
    return httpClient(`${db_api_url}/${resource}/deleteMany?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;
