import * as React from "react";
import Paper from "@mui/material/Paper";

export const EditorCheatSheet = () => {
  return(
    <Paper sx={{margin: 2, padding: 2}}>
      <h2>Jungle</h2>
      <a
        target="_blank"
        href={'https://docs.google.com/document/d/1KSQM7FeHG2D5iz3inv2kx8yvxCyCz9B4ol5DhjUo6Yo/view?usp=sharing'}>
        Jungle Documentation
      </a>
      <h2>Editor</h2>
      <h3>Cheat sheet</h3>
      <ul>
        <li>Command palette: F1</li>
        <li>Format document: ⇧ + ⌥ + F</li>
        <li>Move line up/down: ⌥ + ↑/↓</li>
        <li>Duplicate line up/down: ⇧ + ⌥ + ↑/↓</li>
        <li>Multi cursor: ⌥ + Click</li>
        <li>Multi cursor with arrows: ⌥ + ⌘ + ↑/↓</li>
      </ul>
    </Paper>
  )
}
