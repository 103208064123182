import {db_api_url} from "../config";

const authProvider = {
  login: ({ username, password }) =>  {
    const loginUrl = db_api_url + '/user/sign-in';
    return fetch(loginUrl, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        console.log('token: ', token);
        localStorage.setItem('token', token);
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('token')
    ? Promise.resolve()
    : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: params => Promise.resolve(),
};

export default authProvider;
