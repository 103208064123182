import * as React from "react";
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {List, Datagrid, TextField, Show, Tab, Pagination, SelectInput, Filter} from 'react-admin';
import {EditButton, Edit, TextInput, ImageField, TabbedForm, FormTab} from 'react-admin';
import {TabbedShowLayout, ArrayInput, SimpleFormIterator, Create, ReferenceInput} from 'react-admin';
import { useRecordContext } from 'react-admin';
import {required} from 'react-admin';
import {CustomShowActions} from "./CustomComponents";

export const TopicList = props => {
  return (<List {...props} pagination={<MyPagination />} filters={<TopicsFilter/>} >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="_id" label="Name"/>
      <TextField source="name.fr" label="Display name"/>
      <TextField source="moduleId" label="Parent Module"/>
      <TextField source="group.name" label="Group"/>
      <TextField source="language" label="Language"/>
      <ImageField source="thumbnail" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }}/>
      <TextField source="defaultActivated" label="By default"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

const TopicsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Display name (fr)" source="name.fr" />
    <TextInput label="Parent Module" source="moduleId" />
    <TextInput label="Language" source="language" />
  </Filter>
);

export const TopicEdit = props => {
  const classes = useStyles();
  return (<Edit {...props}>
    <TabbedForm>
      <FormTab label="General">
        <TextInput source="_id" label="Name" validate={[required()]} />
        <TextInput source="name.fr" label="Display name fr" validate={[required()]} />
        <TextInput source="name.en" label="Display name en" validate={[required()]} />
        <ReferenceInput label="Module" source="moduleId" reference="module" perPage={100} sort={{order:'ASC', field: '_id'}}>
          <SelectInput optionText="_id" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="group.name" label="Group"/>
        <SelectInput source="language" choices={[
          { id: 'fr', name: 'Francais' },
          { id: 'en', name: 'Anglais' },
        ]} validate={[required()]} />
        <ImageField source="thumbnail" className={classes.imgContainer}/>
        <TextInput source="thumbnail" label="Thumbnail" validate={[required()]} />
        <SelectInput source="defaultActivated" label="Activated by default" choices={[
          { id: 1, name: 'True'},
          { id: 0, name: 'False'},
        ]} validate={[required()]} />
      </FormTab>
      <FormTab label="Feeds">
        <ArrayInput source="feeds">
          <SimpleFormIterator>
            <TextInput source="_id" label="Feed url" fullWidth={true} />
            {/*<ReferenceInput label="Source" source="source" perPage={100} reference="source" sort={{order:'ASC'}}>*/}
            {/*  <AutocompleteInput source="_id" />*/}
            {/*</ReferenceInput>*/}
            <TextInput source="source" label="Source" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      {/* ------------- Twitter ------------- */}
      <FormTab label="Twitter Influencers">
        <ArrayInput source="twitter_influencers_accounts">
          <SimpleFormIterator>
            <TextInput source="_id" label="Twitter id" fullWidth={true} />
            <TextInput source="name" label="Display Name" fullWidth={true} />
            <TextInput source="icon" label="Icon url" fullWidth={true} />
            <SelectInput label="Source type" source="source_type" choices={[
              { id: 'feed', name: 'Feed' },
              { id: 'twitter_influencer', name: 'Twitter influencer' },
              { id: 'twitter_media', name: 'Twitter media' },
            ]} initialValue={'twitter_influencer'} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Twitter Medias">
        <ArrayInput source="twitter_media_accounts">
          <SimpleFormIterator>
            <TextInput source="_id" label="Twitter id" fullWidth={true} />
            <TextInput source="name" label="Display Name" fullWidth={true} />
            <TextInput source="icon" label="Icon url" fullWidth={true} />
            <SelectInput label="Source type" source="source_type" choices={[
              { id: 'feed', name: 'Feed' },
              { id: 'twitter_influencer', name: 'Twitter influencer' },
              { id: 'twitter_media', name: 'Twitter media' },
            ]} initialValue={'twitter_media'}  />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>)
};

export const TopicShow = (props) => {
  const classes = useStyles();
  return (<Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="_id" label="Name"/>
        <TextField source="name.fr" label="Display name fr" />
        <TextField source="name.en" label="Display name en" />
        <TextField source="moduleId" label="Parent Module"/>
        <TextField source="group.name" label="Group"/>
        <TextField source="language" label="Language"/>
        <ImageField source="thumbnail" className={classes.imgContainer}/>
        <TextField source="defaultActivated" label="By default"/>
      </Tab>
      <Tab label="feeds">
        <FeedList source="feeds" label="Feeds" />
      </Tab>
      <Tab label="Twitter influenceurs">
        <TwitterAccountsList source="twitter_influencers_accounts" label="Twitter Influenceurs" />
      </Tab>
      <Tab label="Twitter médias">
        <TwitterAccountsList source="twitter_media_accounts" label="Twitter médias" />
      </Tab>
    </TabbedShowLayout>
  </Show>)
};

const FeedList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const rows = record[source].map(elt => ({...elt, key: elt?._id}))
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Url</TableCell>
            <TableCell>Source</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.key}>
              <TableCell><Link href={row.key}>{row.key}</Link></TableCell>
              <TableCell>{row.source}</TableCell>
              {/*<TableCell>{row.commands}</TableCell>*/}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TwitterAccountsList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  if (!(source in record)) {
    return null;
  }
  const rows = record[source].map(elt => ({...elt, key: elt?._id}))
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Twitter Id</TableCell>
            <TableCell>Display name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.key}>
              <TableCell><Link href={row.key}>{row.key}</Link></TableCell>
              <TableCell>{row.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


// ------------------------------ Topic create ------------------------------

export const TopicCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="_id" label="Id" validate={[required()]}/>
          <TextInput source="name.fr" label="Display name fr" validate={[required()]} />
          <TextInput source="name.en" label="Display name en" validate={[required()]} />
          <ReferenceInput
            label="Module"
            source="moduleId"
            reference="module"
            perPage={100}
            sort={{order:'ASC', field: '_id'}}
            filter={{__t: 'NewsModule'}}
          >
            <SelectInput optionText="_id" validate={[required()]} />
          </ReferenceInput>
          {/*<TextInput source="moduleId" label="Parent Module"/>*/}
          <TextInput source="group.name" label="Group"/>
          <SelectInput source="language" choices={[
            { id: 'fr', name: 'Francais' },
            { id: 'en', name: 'Anglais' },
          ]} validate={[required()]} />
          <ImageField source="thumbnail" sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }} />
          <TextInput source="thumbnail" label="Thumbnail" validate={[required()]}/>
          <SelectInput source="defaultActivated" label="Activated by default" choices={[
            { id: 1, name: 'True'},
            { id: 0, name: 'False'},
          ]} validate={[required()]} />
        </FormTab>
        <FormTab label="feeds">
          <ArrayInput source="feeds">
            <SimpleFormIterator>
              <TextInput source="_id" label="Feed url" fullWidth={true} />
              {/*<ReferenceInput label="Source" source="source" reference="source" sort={{order:'ASC'}}>*/}
              {/*  <SelectInput optionText="_id" />*/}
              {/*</ReferenceInput>*/}
              <TextInput source="source" label="Source" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        {/* ------------- TWITTER ------------- */}
        <FormTab label="Twitter Influencers">
          <ArrayInput source="twitter_influencers_accounts">
            <SimpleFormIterator>
              <TextInput source="_id" label="Twitter id" fullWidth={true} />
              <TextInput source="name" label="Display Name" fullWidth={true} />
              <TextInput source="icon" label="Icon url" fullWidth={true} />
              <SelectInput label="Source type" source="source_type" choices={[
                { id: 'feed', name: 'Feed' },
                { id: 'twitter_influencer', name: 'Twitter influencer' },
                { id: 'twitter_media', name: 'Twitter media' },
              ]} initialValue={'twitter_influencer'} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Twitter Medias">
          <ArrayInput source="twitter_media_accounts">
            <SimpleFormIterator>
              <TextInput source="_id" label="Twitter id" fullWidth={true} />
              <TextInput source="name" label="Display Name" fullWidth={true} />
              <TextInput source="icon" label="Icon url" fullWidth={true} />
              <SelectInput label="Source type" source="source_type" choices={[
                { id: 'feed', name: 'Feed' },
                { id: 'twitter_influencer', name: 'Twitter influencer' },
                { id: 'twitter_media', name: 'Twitter media' },
              ]} initialValue={'twitter_media'} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const useStyles = makeStyles()({
  imgContainer: {
    // '& img': {
      height: 30,
      width: 30,
      objectFit: "contain"
    // }
  }
});
