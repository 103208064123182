import * as React from "react";

import { makeStyles } from 'tss-react/mui';
import {Alert} from '@mui/material';
import {List, Datagrid, TextField, Show, Tab, FormTab, ArrayInput, useRecordContext} from 'react-admin';
import {EditButton, Edit, TextInput,   Pagination, SimpleFormIterator} from 'react-admin';
import {TabbedShowLayout, TabbedForm, TopToolbar, Button} from 'react-admin';

import {CustomShowActions} from "./CustomComponents";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {tts_api_url} from "../config";
import {httpClient} from "../providers/dataProvider";

// ------------------------------------------ LIST -------------------------------------------

export const NlpFilterList = props => {
  return (
    <List {...props} actions={<ListActions />} pagination={<MyPagination/>}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Name"/>
      <TextField source="_id" label="Id"/>
      <EditButton/>
    </Datagrid>
  </List>);
};


const ListActions = ({ basePath, data, resource }) => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  return (
    <TopToolbar>
      {/* Add your custom actions */}
      <Button label="Upload ggsheet emojis" onClick={() => {
        const url = `${tts_api_url}/upload_gg_sheet_emojis/`;
        return httpClient(url, {method: 'POST'}).then((res) => {
          if (res.status === 200) {
            setOpen2(true);
            setTimeout(() => {setOpen2(false)}, 3000);
          }
        });
      }} />
      <Button label="Restart emojis" onClick={() => {
        const url = `${tts_api_url}/update_emojis/`;
        return httpClient(url, {method: 'POST'}).then((res) => {
          if (res.status === 200) {
            setOpen(true);
            setTimeout(() => {setOpen(false)}, 3000);
          }
        });
      }} />
      <Button label="Upload ggsheet transliteration data" onClick={() => {
        const url = `${tts_api_url}/upload_gg_sheet_transliteration/`;
        return httpClient(url, {method: 'POST'}).then((res) => {
          if (res.status === 200) {
            setOpen2(true);
            setTimeout(() => {setOpen2(false)}, 3000);
          }
        });
      }} />
      <Button label="Redémarrer la translitération" onClick={() => {
        const url = `${tts_api_url}/update_transliteration/`;
        return httpClient(url, {method: 'POST'}).then((res) => {
          if (res.status === 200) {
            setOpen(true);
            setTimeout(() => {setOpen(false)}, 3000);
          }
        });
      }} />
      {open2 ? <Alert severity="info">Upload réussi de gg sheet vers MongoDB!</Alert> : null}
      {open ? <Alert severity="info">La translitération a bien redémarré !</Alert> : null}

    </TopToolbar>
  );
}

// ------------------------------------------ SHOW -------------------------------------------

export const NlpFilterShow = (props) => {
  return (
    <Show {...props} actions={<CustomShowActions/>}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="_id" label="Id"/>
          <TextField source="name" label="Name"/>
        </Tab>
        <Tab label="Français">
          <FilterList source="languages" lang="fr"/>

        </Tab>
        <Tab label="English">
          <FilterList source="languages" lang="en"/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};


const FilterList = ({ source, lang}) => {
  const classes = useStyles();
  const record = useRecordContext();
  const filters = record[source][lang];
  if (!filters.length) {
    return null;
  }

  if (typeof filters[0] === 'string') {
    // Array of strings
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Chaines de caractères</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filters.map((row) => (
              <TableRow key={row}>
                <TableCell>{row}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );

  } else if (typeof filters[0] === 'object' && 'key' in filters[0] && 'value' in filters[0]) {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Chaines de caractères</TableCell>
              <TableCell>Remplacement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filters.map((row) => (
              <TableRow key={row.key}>
                <TableCell>{row.key}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (typeof filters[0] === 'object' && 'word1' in filters[0] && 'word2' in filters[0]) {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Mot 1</TableCell>
              <TableCell>Mot 2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filters.map((row) => (
              <TableRow key={row.word1}>
                <TableCell>{row.word1}</TableCell>
                <TableCell>{row.word2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};


const MyPagination = props => (
  <Pagination perPage={20} rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

// ------------------------------------------ EDIT -------------------------------------------

export const NlpFilterEdit = props => {
  // const classes = useStyles();
  return (
    <Edit {...props}>
      <TabbedForm>
        {/* --- Summary --- */}
        <FormTab label="Summary">
          <TextField source="_id" label="Id"/>
          <TextInput source="name" label="Name"/>
        </FormTab>
        {/* --- Time slots --- */}
        <FormTab label="Français">
          <SimpleArrayInput source="languages" lang="fr"/>
        </FormTab>
        <FormTab label="English">
          <SimpleArrayInput source="languages" lang="en" />
        </FormTab>

      </TabbedForm>
    </Edit>
  );
};

const SimpleArrayInput = ({source, lang}) => {
  const classes = useStyles();
  const record = useRecordContext();
  const filters = record[source][lang];
  if (!filters.length) {
    return null;
  }
  const arraySource = source + '.' + lang;
  if (typeof filters[0] === 'string') {
    return (
      <ArrayInput source={arraySource}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
    );
  } else if (typeof filters[0] === 'object' && 'key' in filters[0] && 'value' in filters[0]) {
    return (
      <ArrayInput source={arraySource}>
        <SimpleFormIterator>
          <TextInput label="Mot source" source="key" formClassName={classes.inlineBlock} />
          <TextInput label="Remplacé par" source="value" formClassName={classes.inlineBlock} />
        </SimpleFormIterator>
      </ArrayInput>
    );
  } else if (typeof filters[0] === 'object' && 'word1' in filters[0] && 'word2' in filters[0]) {
    return (
      <ArrayInput source={arraySource}>
        <SimpleFormIterator>
          <TextInput label="Mot 1" source="word1" formClassName={classes.inlineBlock} />
          <TextInput label="Mot 2" source="word2" formClassName={classes.inlineBlock} />
        </SimpleFormIterator>
      </ArrayInput>
    );
  }
}

// const digitsOnly = new RegExp('^[0-9]+$');
// const parseStringInput = text => digitsOnly.test(text) ? parseInt(text) : text;


const useStyles = makeStyles()((theme) => ({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: "contain"
    }
  },
  card: {
    elevation: 3,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#f6f6f6',
  },
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
}));
