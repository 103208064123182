import * as React from "react";
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {List, Datagrid, TextField, Show, Tab, SimpleFormIterator} from 'react-admin';
import {EditButton, Edit, TextInput, FormTab, SelectInput} from 'react-admin';
import {TabbedShowLayout, ArrayField, ArrayInput, TabbedForm, NumberInput} from 'react-admin';
import { useRecordContext } from 'react-admin';
import {CustomShowActions} from "./CustomComponents";

export const ModuleMetadataList = props => (
  <List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" label="Name"/>
      <TextField source="name.fr" label="Display name" />
      <TextField source="category" label="Category" />
      <TextField source="__v" label="Version" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ModuleMetadataEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextField source="id" label="Name"/>
        <TextInput source="name.fr" label="Display name fr" />
        <TextInput source="name.en" label="Display name en" />
        <SelectInput source="category" label="Category" choices={[
          { id: 'content', name: 'Content' },
          { id: 'service', name: 'Service' },
        ]}/>
        <NumberInput source="balanceWeight" label="Balance weight" />
        <NumberInput source="defaultPriority" label="Default priority" />
        <TextInput source="color" label="Color" />
      </FormTab>
      {/*<TextInput source="modules" label="Modules" />*/}
      <FormTab label="Setup">
        <ArrayInput source="setup">
          <SimpleFormIterator>
            <TextInput source="param" label="Paramètre" fullWidth={true}/>
            <TextInput source="value" label="Valeur" parse={parseStringInput}/>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ModuleMetadataShow = (props) => (
  <Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="Name"/>
        <TextField source="name.fr" label="Display name - french" />
        <TextField source="name.en" label="Display name - english" />
        <TextField source="category" label="Category" />
        <TextField source="__v" label="Version" />
        <TextField source="balanceWeight" label="Balance weight" />
        <TextField source="defaultPriority" label="Default priority" />
        <TextField source="color" label="Color" />
      </Tab>
      <Tab label="modules">
        <ModulesList source="modules" />
      </Tab>
      <Tab label="Setup">
        <ArrayField source="setup">
          <Datagrid>
            <TextField source="param" label="Paramètre"/>
            <TextField source="value" label="Valeur"/>
          </Datagrid>
        </ArrayField>
      {/*<Array source="setup" />*/}
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const ModulesList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  // const rows = record[source].map(elt => ({...elt, key: elt}))
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Module name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record[source].map((row) => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const digitsOnly = new RegExp('^[0-9]+$');
const parseStringInput = text => digitsOnly.test(text) ? parseInt(text) : text;

const useStyles = makeStyles()({
  table: {
    minWidth: 650,
  },
});
