import * as React from "react";
import {cloneElement} from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Collapse} from '@mui/material';

import {List, Datagrid, TextField, EmailField, Show, Tab} from 'react-admin';
import {EditButton, SimpleForm, Edit, TextInput, NumberInput} from 'react-admin';
import {TabbedShowLayout, SelectInput, Pagination, Filter} from 'react-admin';
import {downloadCSV, TopToolbar, ExportButton} from 'react-admin';
import { useRecordContext } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {CustomShowActions} from "./CustomComponents";

export const UserList = props => (
  <List {...props} pagination={<MyPagination/>} filters={<UserFilter />} actions={<ListActions />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <EmailField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="status" />
      <TextField source="role" />
      <TextField source="lang" />
      <TextField source="deviceInfo.juiceVersion" label="Juice version"/>
      {/*<InviteButton />*/}
      <EditButton />
    </Datagrid>
  </List>
);

const ListActions = (props) => {
  return (
    <TopToolbar>
      {cloneElement(props.filters, { context: 'button' })}
      {/*<CreateButton/>*/}
      <ExportButton exporter={exporter} maxResults={100000}/>
    </TopToolbar>
    );
}

const exporter = (users) => {
  // const usersForExport = users.map(user => {
  //   user.lastConnection = user.activity?.slice(-1);
  //   return user;
  // });
  const csv = convertToCSV({
    data: users,
    // select and order fields in the export
    fields: ['firstName', 'lastName', 'email', 'status', 'role', 'invitationSource', 'creationDate', 'lang']
  });
  downloadCSV(csv, 'juice_users'); // download as 'posts.csv` file
}

// class InviteButton extends React.Component {
//   handleClick = () => {
//     const { push, record } = this.props;
//     const body = {
//       email: record.email,
//       firstName: record.firstName,
//       juiceInvite: true,
//     };
//     const url = `${db_api_url}/user/invite-user/`;
//     return httpClient(url, {
//       method: 'POST',
//       body: JSON.stringify(body),
//     }).then(() => {
//         showNotification('User invited');
//         })
//       .catch((e) => {
//         showNotification('Error: Could not invite user', 'warning')
//       });
//   }
//
//   render() {
//     return (
//       <>
//         {this.props.record.status === 'waitlist' ? (
//           <Button style={{color: '#3f51b5'}} label="Invite" onClick={this.handleClick}>
//             Inviter
//           </Button>
//         ) : null}
//       </>
//     );
//   }
// }


const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="email" source="email" />
    <SelectInput source="status" choices={[
      { id: 'waitlist', name: 'Waitlist'},
      { id: 'invited', name: 'Invited'},
      { id: 'pending', name: 'Pending' },
      { id: 'verified', name: 'Verified' },
      { id: 'banned', name: 'Banned' },
    ]} />
    <SelectInput source="role" choices={[
      { id: 'freemium', name: 'Freemium' },
      { id: 'premium', name: 'Premium' },
      { id: 'admin', name: 'Admin' },
      { id: 'iad', name: 'IAD' },
      { id: 'macif', name: 'MACIF' },
    ]} />
    <SelectInput source="lang" choices={[
      { id: 'fr', name: 'Francais' },
      { id: 'en', name: 'Anglais' },
    ]} />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
  </Filter>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <SelectInput source="status" choices={[
        { id: 'waitlist', name: 'Waitlist'},
        { id: 'invited', name: 'Invited'},
        { id: 'pending', name: 'Pending' },
        { id: 'verified', name: 'Verified' },
        { id: 'banned', name: 'Banned' },
      ]} />
      <SelectInput source="role" choices={[
        { id: 'freemium', name: 'Freemium' },
        { id: 'premium', name: 'Premium' },
        { id: 'admin', name: 'Admin' },
        { id: 'iad', name: 'IAD' },
        { id: 'macif', name: 'MACIF' },
      ]} />
      <SelectInput source="lang" choices={[
        { id: 'fr', name: 'Francais' },
        { id: 'en', name: 'Anglais' },
      ]} />
      <NumberInput source="remainingInvites" label="Invitations restantes" />

      {/*<ArrayInput source="modulesPreferences">*/}
      {/*  <SimpleFormIterator>*/}
      {/*    <TextInput source="module" label="Module"/>*/}
      {/*    <PreferenceInput source="preference" />*/}
      {/*  </SimpleFormIterator>*/}
      {/*</ArrayInput>*/}
    </SimpleForm>
  </Edit>
);

// const PreferenceInput = ({ source, record = {}}) => {
//   console.log('recordn,bb,: ', record, source);
//   return (
//     <>
//       {record.preference.activated >= 0 ?
//         <TextInput source="activated" label="Activated"/>
//         : <TextInput source="priority" label="Preference"/>
//       }
//     </>
//   )
// }

export const UserShow = (props) => (
  <Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <EmailField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="gender" />
        <TextField source="generation" />
        <TextField source="birthday" />
        <TextField source="notifications" />
        <TextField source="localization" />
        <TextField source="authOrigin" />
        <TextField source="musicProvider" />
        <TextField source="status" />
        <TextField source="role" />
        <TextField source="lang" />
        <TextField source="remainingInvites" label="Invitations restantes" />
        <TextField source="job" />
        <TextField source="description" />
        <h3>Device</h3>
        <TextField source="deviceInfo.model" label="Device model"/>
        <TextField source="deviceInfo.brand" label="Device brand"/>
        <TextField source="deviceInfo.manufacturer" label="Device manufacturer"/>
        <TextField source="deviceInfo.systemName" label="System name"/>
        <TextField source="deviceInfo.systemVersion" label="System version"/>
        <TextField source="deviceInfo.device" label="Device"/>
        <TextField source="deviceInfo.macAddress" label="Mac address"/>
        <TextField source="deviceInfo.juiceVersion" label="Juice version"/>
      </Tab>
      <Tab label="Onboarding">
        <OnboardingList source="onBoardingSteps" />
      </Tab>
      <Tab label="modulesPreferences">
        <ModulesPreferencesList source="modulesPreferences" />
      </Tab>
      <Tab label="activity">
        <ActivityList source="activity" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const OnboardingList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  // const fullList = [];
  const fullList = record[source].map(elt => ({
    id: elt._id,
    timestamp: elt.timestamp,
    key: elt.key,
    value: elt.value,
  }));

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Step</TableCell>
            <TableCell>value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fullList.map((row) => (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row">{row.timestamp}</TableCell>
              <TableCell>{row.key}</TableCell>
              <TableCell>{JSON.stringify(row.value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ModulesPreferencesList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  // const fullList = [];
  const fullList = record[source].map(elt => ({
    key: elt._id,
    module: elt.module,
    activated: elt.preference.activated,
    priority: elt.preference.priority >= 0 ? Math.round(elt.preference.priority * 100) / 100 : null,
  }));

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Module</TableCell>
            <TableCell>Activated</TableCell>
            <TableCell>Priority</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fullList.map((row) => (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row">{row.module}</TableCell>
              <TableCell>{row.activated}</TableCell>
              <TableCell>{row.priority}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ActivityRow = ({row}) => {
  const [open, setOpen] = React.useState(false);
  if (!row.commands) {
    return null;
  }
  const commands = row.commands;
  return (<>
    <TableRow key={row.key} onClick={() => {
      setOpen(!open);
    }}>
      <TableCell component="th" scope="row">{row.startDate}</TableCell>
      <TableCell>{row.lastCheckpoint && (new Date(row.lastCheckpoint)).toISOString()}</TableCell>
      <TableCell>{row.endDate}</TableCell>
      <TableCell>{humanizedMiliseconds(row.playingTime)}</TableCell>
      {/*<TableCell>{row.commands}</TableCell>*/}
    </TableRow>
    <Collapse
      in={open}
      timeout="auto"
      // component={collapseComponent}
      unmountOnExit
    >
      <TableContainer component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>Command</TableCell>
            <TableCell>Speech command ?</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>Message id</TableCell>
            <TableCell>Message module</TableCell>
            <TableCell>Message url</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {commands.map((command) => (
            <TableRow>
              <TableCell>{command.commandName}</TableCell>
              <TableCell>{JSON.stringify(command.isSpeechCommand)}</TableCell>
              <TableCell>{command.timeStamp}</TableCell>
              <TableCell>{command.message?.uid}</TableCell>
              <TableCell>{command.message?.moduleId}</TableCell>
              <TableCell>{command.message?.url}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Collapse>
  </>);
};

const ActivityList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const rows = record[source]?.map(elt => ({...elt, key: elt?.startDate}));

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Start</TableCell>
            <TableCell>Last Checkpoint</TableCell>
            <TableCell>End</TableCell>
            <TableCell>Playing time</TableCell>
            {/*<TableCell>Commands</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <ActivityRow row={row}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const humanizedMiliseconds = milliseconds => {
  const totalSeconds = Math.round(milliseconds / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  return (totalHours ? totalHours + 'h ' : '') +
    (totalMinutes ? totalMinutes % 60 + 'm ' : '') +
    totalSeconds % 60 + 's';
}

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const useStyles = makeStyles()({
  table: {
    minWidth: 650,
  },
});
