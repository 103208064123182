import React, { useRef, useEffect, useState } from 'react';
import {useInput, useRecordContext} from 'react-admin';
import MonacoEditor from 'react-monaco-editor';

export const JsonEditorInput = ({source, label, onValidJsonChange, collapsed}) => {
  const { id, field, fieldState } = useInput({source});
  const editorRef = useRef(null);
  const [isValidJson, setIsValidJson] = useState(true);
  const [error, setError] = useState("");

  const customValidation = (newValue) => {
    // Todo jungle validation
    return true;
  };

  const handleEditorChange = (newValue) => {
    try {
      // Linter
      // if (lint) {
      //   const errors = lint(newValue);
      //   if (editorRef.current) {
      //     const model = editorRef.current.getModel();
      //     monaco.editor.setModelMarkers(model, 'lint', errors);
      //   }
      // }

      // Errors
      const parsedValue = JSON.parse(newValue);
      const isValid = customValidation(parsedValue);
      if (isValid) {
        setIsValidJson(true);
        onValidJsonChange(false);
        setError('');
        field.onChange(parsedValue);
      } else {
        onValidJsonChange(true);
        setIsValidJson(false);
      }
    } catch (e) {
      onValidJsonChange(true);
      setError('Invalid JSON');
      setIsValidJson(false);
    }
  };

  const editorOptions = {
    language: 'json',
    // theme: 'vs-dark',
    automaticLayout: true,
  };

  // useEffect(() => {
  //   console.log('Editor ref: ', editorRef.current.editor);
  //   if (editorRef.current?.editor?.getModel) {
  //     editorRef.current.editor.getModel().updateOptions({
  //       validate: true,
  //       allowComments: true,
  //     });
  //   }
  // }, [editorRef]);

  useEffect(() => {
    if (collapsed) {
      editorRef.current.trigger('fold', `editor.unfoldAll`);
      editorRef.current.trigger('fold', `editor.foldLevel${collapsed + 1}`);
    }
  }, [collapsed]);

  return (
    <label htmlFor={id}>
      {label}
      <MonacoEditor
        editorDidMount={(editor) => {
          editorRef.current = editor;
        }}
        id={id}
        width="1100"
        height="600"
        language="json"
        theme="vs-dark"
        value={JSON.stringify(field.value, null, 4)}
        options={editorOptions}
        onChange={handleEditorChange}
      />
      {!isValidJson && <span>{error}</span>}
      {fieldState.error && <span>{fieldState.error.message}</span>}
    </label>
  );
};

export const JsonEditorShow = ({source, label, collapsed}) => {
  const record = useRecordContext()[source];
  const editorRef = useRef(null);

  useEffect(() => {
    if (collapsed) {
      editorRef.current.trigger('fold', `editor.unfoldAll`);
      editorRef.current.trigger('fold', `editor.foldLevel${collapsed + 1}`);
    }
  }, [collapsed]);

  return (
    <label>
      {label}
      <MonacoEditor
        editorDidMount={(editor) => {
          editorRef.current = editor;
        }}
        width="1100"
        height="600"
        language="json"
        theme={"vs"}
        value={JSON.stringify(record, null, 4)}
        options={{readOnly: true}}
      />
    </label>
  );
};


export default JsonEditorInput;
