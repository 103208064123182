import * as React from "react";
import { makeStyles } from 'tss-react/mui';

import {List, Datagrid, TextField, Show, Tab, Pagination, SelectInput, Filter} from 'react-admin';
import {EditButton, SimpleForm, Edit, TextInput, ImageField, TabbedForm, FormTab} from 'react-admin';
import {TabbedShowLayout, NumberInput, Create, ReferenceInput} from 'react-admin';
import {required} from 'react-admin';
import {CustomShowActions} from "./CustomComponents";
import { ColorField, ColorInput } from 'react-admin-color-picker';

export const NewscastList = props => {
  const classes = useStyles();
  return (<List {...props} pagination={<MyPagination />} filters={<NCFilter/>}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      {/*<TextField source="_id" label="Name"/>*/}
      <TextField source="name.fr" label="Display name"/>
      <TextField source="moduleId" label="Parent Module"/>
      <TextField source="source" label="Source"/>
      <TextField source="juiceScore" label="Juice Score"/>
      <TextField source="peremption" label="Per-emption"/>
      <TextField source="maxDuration" label="Max Duration"/>
      <TextField source="periodicity" label="Period- icité"/>
      {/*<TextField source="group.name" label="Group"/>*/}
      <TextField source="language" label="Lang"/>
      <ColorField source="dominant_color" label="Color" className={classes.transparent} />
      <ImageField source="thumbnail" sx={{'& img': {maxHeight: 60, maxWidth: 60, objectFit: 'contain'}}}/>
      <TextField source="defaultActivated" label="On"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

const NCFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Display name (fr)" source="name.fr" />
    <TextInput label="Parent Module" source="moduleId" />
    <TextInput label="Language" source="language" />
    <TextInput label="Source" source="source" />
    <TextInput source="juiceScore" label="Juice Score"/>
    <TextInput source="maxDuration" label="Max Duration"/>
    <TextInput source="periodicity" label="Periodicité"/>
  </Filter>
);

export const NewscastEdit = props => {
  return (<Edit {...props}>
    <SimpleForm>
      {/*<TextInput source="_id" label="Name" validate={[required()]} />*/}
      <TextInput source="name.fr" label="Display name fr" validate={[required()]} />
      <TextInput source="name.en" label="Display name en" validate={[required()]} />
      <ReferenceInput label="Module" source="moduleId" reference="module" perPage={100} sort={{order:'ASC', field: '_id'}}>
        <SelectInput optionText="_id" validate={[required()]} />
      </ReferenceInput>
      <TextInput source="group.name" label="Group"/>
      <SelectInput source="language" choices={[
        { id: 'fr', name: 'Francais' },
        { id: 'en', name: 'Anglais' },
      ]} validate={[required()]} />
      <ImageField source="thumbnail" sx={{'& img': {maxHeight: 60, maxWidth: 60, objectFit: 'contain'}}}/>
      <TextInput source="thumbnail" label="Thumbnail" validate={[required()]} />
      <SelectInput source="defaultActivated" label="Activated by default" choices={[
        { id: 1, name: 'True'},
        { id: 0, name: 'False'},
      ]} validate={[required()]} />
      <TextInput source="feed" label="Feed" validate={[required()]} />
      <TextInput source="author" label="Auteur"/>
      <TextInput source="source" label="Source"/>
      <TextInput source="periodicity" label="Périodicité"/>
      <NumberInput source="juiceScore" label="Juice Score"/>
      <NumberInput source="maxDuration" label="Max Duration"/>
      <NumberInput source="hasJingle" label="Has a jingle"/>
      <NumberInput source="peremption" label="Peremption"/>
      <ColorInput source="dominant_color" label="Color"/>


      {/*<ArrayInput source="feeds">*/}
      {/*  <SimpleFormIterator>*/}
      {/*    <TextInput source="_id" label="Feed url" fullWidth={true} />*/}
      {/*    /!*<ReferenceInput label="Source" source="source" perPage={100} reference="source" sort={{order:'ASC'}}>*!/*/}
      {/*    /!*  <AutocompleteInput source="_id" />*!/*/}
      {/*    /!*</ReferenceInput>*!/*/}
      {/*    <TextInput source="source" label="Source" />*/}
      {/*  </SimpleFormIterator>*/}
      {/*</ArrayInput>*/}
    </SimpleForm>
  </Edit>)
};

export const NewscastShow = (props) => {
  return (<Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="_id" label="Name"/>
        <TextField source="name.fr" label="Display name fr" />
        <TextField source="name.en" label="Display name en" />
        <TextField source="moduleId" label="Parent Module"/>
        <TextField source="group.name" label="Group"/>
        <TextField source="language" label="Language"/>
        <ImageField source="thumbnail" sx={{'& img': {maxHeight: 60, maxWidth: 60, objectFit: 'contain'}}}/>
        <TextField source="defaultActivated" label="By default"/>
        <TextField source="feed" label="Feed"/>
        <TextField source="author" label="Auteur"/>
        <TextField source="source" label="Source"/>
        <TextField source="periodicity" label="Périodicité"/>
        <TextField source="juiceScore" label="Juice Score"/>
        <TextField source="maxDuration" label="Max Duration"/>
        <TextField source="hasJingle" label="Has a jingle"/>
        <TextField source="peremption" label="Peremption"/>
        <ColorField source="dominant_color" label="Color"/>
      </Tab>
    </TabbedShowLayout>
  </Show>)
};

// const FeedList = ({ source, record = {}}) => {
//   const classes = useStyles();
//   const rows = record[source].map(elt => ({...elt, key: elt?._id}))
//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell>Url</TableCell>
//             <TableCell>Source</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rows.map((row) => (
//             <TableRow key={row.key}>
//               <TableCell><Link href={row.key}>{row.key}</Link></TableCell>
//               <TableCell>{row.source}</TableCell>
//               {/*<TableCell>{row.commands}</TableCell>*/}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// ------------------------------ Newscast create ------------------------------

export const NewscastCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="summary">
          {/*<TextInput source="_id" label="Id" validate={[required()]}/>*/}
          <TextInput source="name.fr" label="Display name fr" validate={[required()]} />
          <TextInput source="name.en" label="Display name en" validate={[required()]} />
          <ReferenceInput
            label="Module"
            source="moduleId"
            reference="module"
            perPage={100}
            sort={{order:'ASC', field: '_id'}}
            filter={{__t: 'NewscastModule'}}
          >
            <SelectInput optionText="_id" validate={[required()]} />
          </ReferenceInput>
          {/*<TextInput source="moduleId" label="Parent Module"/>*/}
          <TextInput source="group.name" label="Group"/>
          <SelectInput source="language" choices={[
            { id: 'fr', name: 'Francais' },
            { id: 'en', name: 'Anglais' },
          ]} validate={[required()]} />
          <ImageField source="thumbnail" sx={{'& img': {maxHeight: 60, maxWidth: 60, objectFit: 'contain'}}} />
          <TextInput source="thumbnail" label="Thumbnail" validate={[required()]}/>
          <TextInput source="feed" label="Feed" validate={[required()]}/>
          <TextInput source="author" label="Auteur"/>
          <TextInput source="source" label="Source"/>
          <TextInput source="periodicity" label="Périodicité"/>
          <NumberInput source="juiceScore" label="Juice Score"/>
          <NumberInput source="maxDuration" label="Max Duration"/>
          <NumberInput source="hasJingle" label="Has a jingle"/>
          <NumberInput source="peremption" label="Peremption"/>
          <SelectInput source="defaultActivated" label="Activated by default" choices={[
            { id: 1, name: 'True'},
            { id: 0, name: 'False'},
          ]} validate={[required()]} />
          <ColorInput source="dominant_color" label="Color"/>
        </FormTab>
        {/*<FormTab label="feeds">*/}
        {/*  <ArrayInput source="feeds">*/}
        {/*    <SimpleFormIterator>*/}
        {/*      <TextInput source="_id" label="Feed url" fullWidth={true} />*/}
        {/*      /!*<ReferenceInput label="Source" source="source" reference="source" sort={{order:'ASC'}}>*!/*/}
        {/*      /!*  <SelectInput optionText="_id" />*!/*/}
        {/*      /!*</ReferenceInput>*!/*/}
        {/*      <TextInput source="source" label="Source" />*/}
        {/*    </SimpleFormIterator>*/}
        {/*  </ArrayInput>*/}
        {/*</FormTab>*/}
      </TabbedForm>
    </Create>
  );
};

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const useStyles = makeStyles()({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: "contain"
    }
  },
  transparent: {
    fontSize: 0,
  },
});
