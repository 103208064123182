import * as React from "react";
// import { makeStyles } from 'tss-react/mui';

import {List, Datagrid, TextField, Show, Tab, Pagination, SelectInput, NumberInput} from 'react-admin';
import {EditButton, SimpleForm, Edit, TextInput} from 'react-admin';
import {TabbedShowLayout, Create, TabbedForm, FormTab} from 'react-admin';
import {CustomShowActions} from "./CustomComponents";

export const SpeechRecoModelsList = props => {
  return (<List {...props} pagination={<MyPagination/>}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="_id" label="Id"/>
      <TextField source="name" label="Display name"/>
      <TextField source="lang" label="Language"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

export const SpeechRecoModelsEdit = props => {
  return (<Edit {...props}>
      <SimpleForm>
        <TextField source="_id" label="Id"/>
        <TextInput source="name" label="Display name"/>
        <SelectInput source="lang" choices={[
          { id: 'fr', name: 'Francais' },
          { id: 'en', name: 'Anglais' },
        ]} />
        <TextInput source="modelTflitePath" label="Model tflite url"/>
        <TextInput source="modelMetadataPath" label="Model metadata json url" />
        <NumberInput source="predictionParams.overlapRatio" label="Overlap Ratio" min={0} max={1} step={0.01} />
        <h3>Trigger thresholds</h3>
        <NumberInput source="predictionParams.threshold.vas_y_juice" label="Vas-y Juice" min={0} max={1} step={0.01} />
        <NumberInput source="predictionParams.threshold.arrete_juice" label="Arrête Juice" min={0} max={1} step={0.01} />
        <NumberInput source="predictionParams.threshold.avance_juice" label="Avance Juice" min={0} max={1} step={0.01} />
        <NumberInput source="predictionParams.threshold.recule_juice" label="Recule Juice" min={0} max={1} step={0.01} />
        <NumberInput source="predictionParams.threshold.change_juice" label="Change Juice" min={0} max={1} step={0.01} />
        <NumberInput source="predictionParams.threshold.marque_juice" label="Marque Juice" min={0} max={1} step={0.01} />
      </SimpleForm>
    </Edit>)
};

export const SpeechRecoModelsShow = (props) => {
  return (
    <Show {...props} actions={<CustomShowActions/>}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="_id" label="Id"/>
          <TextField source="name" label="Model name"/>
          <TextField source="lang" label="Model language" />
          <TextField source="modelTflitePath" label="Model tflite url"/>
          <TextField source="modelMetadataPath" label="Model metadata json url" />
          <TextField source="predictionParams.overlapRatio" label="Overlap Ratio" />
          <h3>Thresholds</h3>
          <TextField source="predictionParams.threshold.vas_y_juice" label="Vas y Juice" />
          <TextField source="predictionParams.threshold.arrete_juice" label="Arrête Juice" />
          <TextField source="predictionParams.threshold.avance_juice" label="Avance Juice" />
          <TextField source="predictionParams.threshold.recule_juice" label="Recule Juice" />
          <TextField source="predictionParams.threshold.change_juice" label="Change Juice" />
          <TextField source="predictionParams.threshold.marque_juice" label="Marque Juice" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const SpeechRecoModelsCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" label="Display name"/>
          <SelectInput source="lang" choices={[
            { id: 'fr', name: 'Francais' },
            { id: 'en', name: 'Anglais' },
          ]} />
          <TextInput source="modelTflitePath" label="Model tflite url"/>
          <TextInput source="modelMetadataPath" label="Model metadata json url" />
          <NumberInput source="predictionParams.overlapRatio" label="Overlap Ratio" min={0} max={1} step={0.01} />
          <h3>Trigger thresholds</h3>
          <NumberInput source="predictionParams.threshold.vas_y_juice" label="Vas-y Juice" min={0} max={1} step={0.01} />
          <NumberInput source="predictionParams.threshold.arrete_juice" label="Arrête Juice" min={0} max={1} step={0.01} />
          <NumberInput source="predictionParams.threshold.avance_juice" label="Avance Juice" min={0} max={1} step={0.01} />
          <NumberInput source="predictionParams.threshold.recule_juice" label="Recule Juice" min={0} max={1} step={0.01} />
          <NumberInput source="predictionParams.threshold.change_juice" label="Change Juice" min={0} max={1} step={0.01} />
          <NumberInput source="predictionParams.threshold.marque_juice" label="Marque Juice" min={0} max={1} step={0.01} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

// const useStyles = makeStyles()({
//   imgContainer: {
//     '& img': {
//       height: 50,
//       width: 50,
//       objectFit: "contain"
//     }
//   }
// });
