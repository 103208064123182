import * as React from "react";
import {Paper} from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import {Typography} from '@mui/material';
import {ListComponent} from "./ListComponent";

export const AudioDirectionComponent = ({audioDirection, title}) => {
  // if (!audioDirection) {
  //   return null;
  // }
  return (
    <>
      <h2>{title}</h2>
      <TrackComponent track={audioDirection?.track} title={'Track'}/>
      <ParametersComponent parameters={audioDirection?.parameters} title={'Parameters'}/>
      <TrackComponent track={audioDirection?.backgroundTrack} title={'Background Track'}/>
      <ParametersComponent parameters={audioDirection?.backgroundParameters} title={'Background Parameters'}/>
    </>
  )
}

export const ParametersComponent = ({parameters, title}) => {
  // if (!parameters) {
  //   return null;
  // }
  const parameterFields = ['Parameter', 'Value'];
  const parameterData = Object.keys(parameters || {}).map(param =>
    ({Parameter: param, Value: JSON.stringify(parameters[param])})
  );
  return (
    <>
      <h4>{title}</h4>
      <ListComponent fields={parameterFields} data={parameterData} />
    </>
  );
}

export const TrackComponent = ({track, title}) => {
  const classes = useStyles();
  // if (!track) {
  //   return null;
  // }
  return (
    <>
      <h4>{title}</h4>
      <div className={classes.root}>
        {(track || []).map(elt =>
          <Paper className={classes.box}>
            <Typography className={classes.boxText}>{elt}</Typography>
          </Paper>
        )}
      </div>
    </>
  );
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(20),
      height: theme.spacing(3),
    },
  },
  box: {
    elevation: 3,
    backgroundColor: '#d9d9d9',
  },
  boxText: {
    'text-align': 'center',
    fontSize: 14
  }
}));
