import * as React from "react";
import {useState} from 'react';

import {List, Datagrid, TextField, Show, Tab, TabbedForm, FormTab} from 'react-admin';
import {EditButton, Edit, TabbedShowLayout, TextInput, Button} from 'react-admin';
import {CustomShowActions, CustomToolbar} from "./CustomComponents";
import { makeStyles } from 'tss-react/mui';
import JsonEditorInput, {JsonEditorShow} from "./JsonIDE";
import ButtonRow from './ButtonRow';
import {EditorCheatSheet} from "./cheatSheets";

export const JungleAudioDirectionList = props => {
  return (<List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Id"/>
      <TextField source="minJuiceVersion" label="Version"/>
      <EditButton/>
    </Datagrid>
  </List>)
};

// --------------------------------- Show ---------------------------------

export const JungleAudioDirectionShow = (props) => {
  const [collapsed, setCollapsed] = React.useState(1);
  return (<Show {...props} actions={<CustomShowActions/>}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" label="Name"/>
        <TextField source="minJuiceVersion" label="Version"/>
        <TextField source="description" label="Description"/>
      </Tab>
      <Tab label="Expressions">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="expr" collapsed={collapsed} />
      </Tab>

      <Tab label="Sequence">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="sequence" collapsed={collapsed} />
      </Tab>

      <Tab label="Message">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="message" collapsed={collapsed} />
      </Tab>

      <Tab label="assets (deprecated)">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="assets" collapsed={collapsed} />
      </Tab>
      <Tab label="texts (deprecated)">
        <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
        <JsonEditorShow source="texts" collapsed={collapsed} />
      </Tab>

    </TabbedShowLayout>
  </Show>)
};

// --------------------------------- Edit ---------------------------------

export const JungleAudioDirectionEdit = props => {
  const [collapsed, setCollapsed] = React.useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Edit {...props}>
      <TabbedForm sanitizeEmptyValues={false} toolbar={<CustomToolbar disabled={isDisabled}/>}>

        <FormTab label="Summary">
          <TextField source="name" label="Name"/>
          <TextInput source="minJuiceVersion" label="Version"/>
          <TextInput source="description" label="Description"/>
        </FormTab>

        {/* --- Expressions --- */}
        <FormTab label="Expressions">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='expr' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Sequence --- */}
        <FormTab label="Sequence">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='sequence' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Message --- */}
        <FormTab label="Message">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='message' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Assets --- DEPRECATED */}
        <FormTab label="Assets (DEPRECATED)">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='assets' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

        {/* --- Texts --- DEPRECATED */}
        <FormTab label="Texts (DEPRECATED)">
          <ButtonRow collapsed={collapsed} setCollapsed={setCollapsed} />
          <JsonEditorInput source='texts' onValidJsonChange={setIsDisabled} collapsed={collapsed}/>
        </FormTab>

      </TabbedForm>
      <EditorCheatSheet/>
    </Edit>
  );
}

const useStyles = makeStyles()((theme) => ({
  buttonRow: {
    flexDirection: 'row',
  }
}));

