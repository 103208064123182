import * as React from "react";

// import Card from "@mui/material/Card";
// import { makeStyles } from 'tss-react/mui';
import {List, Datagrid, TextField, Show, Tab, FormTab, ArrayInput} from 'react-admin';
import {EditButton, Edit, TextInput,   Pagination, SimpleFormIterator} from 'react-admin';
import {TabbedShowLayout, NumberInput, TabbedForm, FormDataConsumer} from 'react-admin';
import {SelectInput, ReferenceInput, ArrayField, ImageField, BooleanInput} from 'react-admin';
import { useRecordContext } from 'react-admin';

import {ListComponent} from "./ListComponent";
import {CustomShowActions} from "./CustomComponents";

// ------------------------------------------ LIST -------------------------------------------

export const GlobalConfigList = props => {
  return (
    <List {...props} pagination={<MyPagination/>}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" label="Name"/>
      <TextField source="_id" label="Id"/>
      <EditButton/>
    </Datagrid>
  </List>);
};

// ------------------------------------------ SHOW -------------------------------------------

export const GlobalConfigShow = (props) => {
  return (
    <Show {...props} actions={<CustomShowActions/>}>
      <TabbedShowLayout>
        <Tab label="Summary">
          <TextField source="_id" label="Id"/>
          <TextField source="name" label="Name"/>
          <TextField source="sessionEndTimeoutMinutes" label="Session Timeout in minutes" />
          <TextField source="expiredAppVersion" label="This app version and the ones before should expire." />
          <TextField source="nTutorialSessions" label="Number of sessions showing tutorial texts to the user." />
          <TextField source="nLatentSequences" label="Number of latent sequences that remain in the flow." />
          <TextField source="speechRecoModels.fr.name" label="Juice keyword spotting french model." />
          <TextField source="speechRecoModels.en.name" label="Juice keyword spotting english model." />
          <TextField source="juiceIsPublic" label="Juice signup is public. If true, the api will bypass the invite system"/>
          <TextField source="iosUpdateUri" label="App update link for IOS"/>
          <TextField source="androidUpdateUri" label="App update link for Android"/>
        </Tab>
        <Tab label="Time Slots">
          <h3>TimeSlots delimiters</h3>
          <TimeSlots source="timeslots" />
          <h3>Today timeslot texts</h3>
          <h4>French</h4>
          <TimeSlotTexts source="todayDeTimeSlot.fr"/>
          <h4>English</h4>
          <TimeSlotTexts source="todayDeTimeSlot.en"/>
          <h3>Yesterday timeslot texts</h3>
          <h4>French</h4>
          <TimeSlotTexts source="yesterdayDeTimeSlot.fr"/>
          <h4>English</h4>
          <TimeSlotTexts source="yesterdayDeTimeSlot.en"/>
        </Tab>
        <Tab label="Feedback Sounds">
          <FeedBackSounds source="feedbackSounds" />
        </Tab>
        <Tab label="Juice Team">
          <ArrayField source="juiceTeam">
            <Datagrid>
              <TextField source="name" />
              <TextField source="position.fr" />
              <TextField source="position.en" />
              <ImageField source="picture" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const TimeSlots = ({ source }) => {
  const record = useRecordContext();
  const fields = ['Timeslot', 'Beginning of time slot'];
  const data = Object.keys(record[source]).map(key => {
    return ({
      Timeslot: key,
      'Beginning of time slot': record[source][key]
    });
  });
  return <ListComponent fields={fields} data={data} />
}

const TimeSlotTexts = ({ source }) => {
  const record = useRecordContext();
  const fields = ['Timeslot', 'Text'];
  const [lvl1, lvl2] = source.split('.');
  const data = Object.keys(record[lvl1][lvl2]).map(key => {
    return ({
      Timeslot: key,
      Text: record[lvl1][lvl2][key]
    });
  });
  return <ListComponent fields={fields} data={data} />
}

const FeedBackSounds = ({ source }) => {
  const record = useRecordContext();
  const fields = ['Command', 'Feedback Sound Url'];
  const data = Object.keys(record[source]).map(key => {
    return ({
      Command: key,
      'Feedback Sound Url': record[source][key]
    });
  });
  return <ListComponent fields={fields} data={data} />
}

const MyPagination = props => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

// ------------------------------------------ EDIT -------------------------------------------

export const GlobalConfigEdit = props => {
  return (
    <Edit {...props}>
      <TabbedForm>
        {/* --- Summary --- */}
        <FormTab label="Summary">
          <TextField source="_id" label="Id"/>
          <TextInput source="name" label="Name"/>
          <ArrayInput source="langs" label="Available languages">
            <SimpleFormIterator>
              <TextInput label="Language" />
            </SimpleFormIterator>
          </ArrayInput>
          <NumberInput source="sessionEndTimeoutMinutes"/>
          <h3>This version and the ones before expired</h3>
          <TextInput source="expiredAppVersion" label="Expiring version" />
          <h3>The app will show tutorial texts until the user has had this many sessions:</h3>
          <TextInput source="nTutorialSessions" label="Number of tutorial sessions" />
          <ReferenceInput label="Speech recognition model fr" source="speechRecoModels.fr._id" reference="speechRecoModel" sort={{order:'ASC'}}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput label="Speech recognition model en" source="speechRecoModels.en._id" reference="speechRecoModel" sort={{order:'ASC'}}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="juiceIsPublic" label="Juice signup is public. If true, the api will bypass the invite system"/>
          <TextInput source="iosUpdateUri" label="App update link for IOS" fullWidth/>
          <TextInput source="androidUpdateUri" label="App update link for Android" fullWidth/>
        </FormTab>
        {/* --- Time slots --- */}
        <FormTab label="Time Slots">
          <h3>Time slots</h3>
          <SimpleJsonInput source="timeslots" />
          <h3>Today timeslot texts</h3>
          <h4>French</h4>
          <SimpleJsonInput source="todayDeTimeSlot.fr"/>
          <h4>English</h4>
          <SimpleJsonInput source="todayDeTimeSlot.en"/>
          <h3>Yesterday timeslot texts</h3>
          <h4>French</h4>
          <SimpleJsonInput source="yesterdayDeTimeSlot.fr"/>
          <h4>English</h4>
          <SimpleJsonInput source="yesterdayDeTimeSlot.en"/>
        </FormTab>
        {/*  --- Feedback sounds --- */}
        <FormTab label="Feedback sounds">
          <h3>Feedback urls</h3>
          <SimpleJsonInput source="feedbackSounds" />
        </FormTab>
        {/*  Juice team */}
        <FormTab label="Juice Team">
          <ArrayInput source="juiceTeam">
            <SimpleFormIterator>
              <TextInput label="Name" source="name" />
              <TextInput label="Role (fr)" source="position.fr" />
              <TextInput label="Role (en)" source="position.en" />
              <TextInput label="Image" source="picture" fullWidth/>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const SimpleJsonInput = props => {
  return (
    <FormDataConsumer>
      {({formData, ...rest}) => {
        let jsonInput = formData;
        props.source.split('.').map(item => {
          jsonInput = jsonInput[item];
          return null;
        })
        if (jsonInput === null) {
          return null
        }
        return (
          <>
            {Object.keys(jsonInput).map(key => {
              return (
                <>
                  <h3>{key}</h3>
                  <TextInput source={props.source + '.' + key} label={key} fullWidth={true} parse={parseStringInput} {...rest} />
                </>
              )
            })}
          </>
        );
      }
      }
    </FormDataConsumer>
  );
}

const digitsOnly = new RegExp('^[0-9]+$');
const parseStringInput = text => digitsOnly.test(text) ? parseInt(text) : text;


// const useStyles = makeStyles()((theme) => ({
//   imgContainer: {
//     '& img': {
//       height: 50,
//       width: 50,
//       objectFit: "contain"
//     }
//   },
//   card: {
//     elevation: 3,
//     margin: theme.spacing(2),
//     padding: theme.spacing(2),
//     backgroundColor: '#f6f6f6',
//   }
// }));
