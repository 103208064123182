import * as React from "react";

import {Button} from "react-admin";
import {makeStyles} from "tss-react/mui";

const ButtonRow = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonRow}>
      {"Expand level: " + props.collapsed}
      <Button label="Expand" onClick={() => props.setCollapsed(Math.min(props.collapsed + 1, 6))}/>
      <Button label="Collapse" onClick={() => props.setCollapsed(Math.max(1, props.collapsed - 1))}/>
    </div>
  )
}

const useStyles = makeStyles()((theme) => ({
  buttonRow: {
    flexDirection: 'row',
  }
}));

export default ButtonRow;
