import * as React from "react";

import { makeStyles } from 'tss-react/mui';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Link from "@mui/material/Link";

export const ListComponent = ({fields, data}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {fields.map(field => <TableCell>{field}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.key}>
              {fields.map(field =>
                field === 'Url' ?
                  <TableCell>
                    <Link href={row[field]}>{row[field]}</Link>
                  </TableCell>
                  : <TableCell>{row[field]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles()((theme) => ({
    imgContainer: {
      '& img': {
        height: 50,
        width: 50,
        objectFit: "contain"
      }
    },
    table: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  })
);
