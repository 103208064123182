import * as React from "react";
import { makeStyles } from 'tss-react/mui';
import { useRecordContext } from 'react-admin';

import {
  List,
  Datagrid,
  TextField,
  Show,
  Tab,
  UrlField,
  SelectInput,
  ReferenceInput,
  NumberInput,
  required,
  DateTimeInput,
  BooleanInput,
  CheckboxGroupInput,
  Filter,
} from 'react-admin';
// import { ColorField, ColorInput } from 'react-admin-color-input';
// import { DateTimeInput } from 'react-admin-date-inputs';
import {EditButton, SimpleForm, Edit, TextInput, ImageField, TabbedForm, FormTab} from 'react-admin';
import {TabbedShowLayout, Create, FormDataConsumer, ArrayInput, SimpleFormIterator} from 'react-admin';
import {CustomShowActions} from "./CustomComponents";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import dataProvider from "../providers/dataProvider";

// --------------------------------- List ---------------------------------

export const ModuleList = props => {
  return (<List {...props} filters={<UserFilter />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="_id" label="Name"/>
      <TextField source="name.fr" label="Display name"/>
      <TextField source="moduleMetadata" label="Module Metadata"/>
      <TextField source="audioDirection" label="Audio Direction"/>
      <TextField source="lang" label="Language"/>
      <TextField source="offer" label="Offer"/>
      <TextField source="defaultPriority" label="Default Priority"/>
      <ImageField source="thumbnail" sx={{'& img': {maxWidth: 50, maxHeight: 50, objectFit: "contain"}}}/>
      <ImageField source="icon" sx={{'& img': {maxWidth: 50, maxHeight: 50, objectFit: "contain"}}}/>
      <EditButton/>
    </Datagrid>
  </List>);
};


const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="_id" />
    <TextInput label="Name" source="name" />
    <SelectInput source="moduleMetadata" choices={[
      { id: 'news', name: 'News'},
      { id: 'newscasts', name: 'Newscast'},
      { id: 'juiceServices', name: 'Service' },
      { id: 'music', name: 'Musique' },
    ]} />
    <SelectInput source="offer" choices={[
      { id: 'ad', name: 'Ad' },
      { id: 'freemium', name: 'Freemium' },
      { id: 'premium', name: 'Premium' },
      { id: 'admin', name: 'Admin' },
      { id: 'iad', name: 'IAD' },
      { id: 'macif', name: 'MACIF' },
    ]} />
    <SelectInput source="lang" choices={[
      { id: 'fr', name: 'Francais' },
      { id: 'en', name: 'Anglais' },
    ]} />
  </Filter>
);


// --------------------------------- Edit ---------------------------------

export const ModuleEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextField source="_id" label="Name" />
        <TextInput source="name.fr" label="Display name fr" />
        <TextInput source="name.en" label="Display name en" />
        <ReferenceInput label="Module Metadata" source="moduleMetadata" reference="moduleMetadata" sort={{order:'ASC'}}>
          <SelectInput optionText="_id" />
        </ReferenceInput>
        <ReferenceInput label="Audio Direction" source="audioDirection" reference="audioDirection" sort={{order:'ASC'}}>
          <SelectInput optionText="_id" />
        </ReferenceInput>
        <SelectInput source="lang" choices={[
          { id: 'fr', name: 'Francais' },
          { id: 'en', name: 'Anglais' },
        ]} />
        <BooleanInput source="cloudService" label="Cloud Service (generic setup)" />
        <SelectInput source="offer" choices={[
          { id: 'ad', name: 'Ad' },
          { id: 'freemium', name: 'Freemium' },
          { id: 'premium', name: 'Premium' },
          { id: 'admin', name: 'Admin' },
          { id: 'iad', name: 'IAD' },
          { id: 'macif', name: 'MACIF' },
        ]} />
        <TextInput source="thumbnail" label="Thumbnail" fullWidth={true} />
        <TextInput source="icon" label="Icon" fullWidth={true} />
        <TextInput source="color" label="Color" />
        <NumberInput label="Default Priority" source='defaultPriority' min={0} max={1} step={0.01}/>
        <NumberInput label="Activated by default" source='defaultActivated' min={0} max={1} step={1}/>
      </FormTab>
      <FormTab label="Setup">
          <SetupInput />
      </FormTab>
      <FormTab label="Messages">
        <MessagesInput />
      </FormTab>
    </TabbedForm>
  </Edit>
);

const MessagesInput = (props) => {
  const classes = useStyles();
  return (
    <ArrayInput source="messages" >
      {/*<Card fullWidth={true} className={classes.card}>*/}
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                // <TextField
                //   source={getSource('url')}
                //   record={scopedFormData}
                // />
              <span className={classes.inputColumns}>
                <TextInput source={getSource('_id')} label="Id (unique)"/>
                <BooleanInput className={classes.booleanInout} source={getSource('activated')} label="Activated" />
              </span>
              );
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <>
                  <div className={classes.inputColumns}>
                    <div className={classes.inputColumn}>
                    <strong>Français</strong>
                    <TextInput source={getSource('name.fr')} label="French Name" fullWidth={true}/>
                    <TextInput source={getSource('description.fr')} label="French description" fullWidth={true}/>
                    <TextInput source={getSource('mainAudio.fr')} label="French Audio for the main track" fullWidth={true}/>
                    <TextInput source={getSource('moreAudio.fr')} label="French Audio for the more track" fullWidth={true}/>
                    <TextInput source={getSource('voices.fr')} label="Voice fr" fullWidth={true}/>
                    </div>
                    <div className={classes.inputColumn}>
                    <strong>English</strong>
                    <TextInput source={getSource('name.en')} label="English Name" fullWidth={true}/>
                    <TextInput source={getSource('description.en')} label="English description" fullWidth={true}/>
                    <TextInput source={getSource('mainAudio.en')} label="English Audio for the main track" fullWidth={true}/>
                    <TextInput source={getSource('moreAudio.en')} label="English Audio for the more track" fullWidth={true}/>
                    <TextInput source={getSource('voices.en')} label="Voice en" fullWidth={true}/>
                    </div>
                  </div>
                  <TextInput source={getSource('background')} label="Audio background" fullWidth={true}/>
                  <strong>Targetting</strong>
                  <div className={classes.inputColumns}>
                    <div className={classes.inputColumn}>
                      <DateTimeInput source={getSource('castStartDate')} label="Broadcast Start Date" />
                      <NumberInput source={getSource('weight')} label="Weight" />
                    </div>
                    <div className={classes.inputColumn}>
                      <DateTimeInput source={getSource('castEndDate')} label="Broadcast End Date" />
                      <NumberInput source={getSource('maxCount')} label="Max count" />
                    </div>
                  </div>
                </>
              );
            }}
          </FormDataConsumer>
          <CheckboxGroupInput source="timeslots" label="Time Slots" choices={[
            { id: 'petit_matin', name: 'petit_matin' },
            { id: 'matinee', name: 'matinee' },
            { id: 'midi', name: 'midi' },
            { id: 'apres_midi', name: 'apres_midi' },
            { id: 'soiree', name: 'soiree' },
            { id: 'nuit', name: 'nuit' }
          ]} />
          <CheckboxGroupInput source="gender" label="Gender" choices={[
            { id: 'female', name: 'Female' },
            { id: 'male', name: 'Male' },
            { id: 'none', name: 'N.B.' },
            { id: null, name: 'Unknown' },
          ]} />
          <CheckboxGroupInput source="generation" label="Generation" choices={[
            { id: 'genAlpha', name: 'Gen Alpha' },
            { id: 'genZ', name: 'Gen Z' },
            { id: 'millenial', name: 'Millenials' },
            { id: 'genX', name: 'Gen X' },
            { id: 'babyBoomer', name: 'Baby Boomer' },
            { id: 'silent', name: 'Silent' },
            { id: null, name: 'Unknown' },
          ]} />
          <TextInput source="geofencing"  label="Geofencing" />
        </SimpleFormIterator>
      {/*</Card>*/}
    </ArrayInput>
  )
}


const SetupInput = (props) => {
  return (
    <ArrayInput source="setup" >
      <SimpleFormIterator disableRemove disableAdd>
        <FormDataConsumer >
          {({formData, scopedFormData, getSource, ...rest}) => {
            if (!scopedFormData) {
              return null;
            }
            const key = Object.keys(scopedFormData.param)[0];
            return (
              <>
                <h3>{key}</h3>
                <TextInput source={getSource('.param.' + key)} initialValue={""} parse={parseStringInput} {...rest} label={key}/>
                <BooleanInput source={getSource('changeable')} label="Changeable" />
              </>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

// const digitsOnly = new RegExp('^\\d*\\.?\\d*$');
const digitsOnly = new RegExp('^\\d+(\\.\\d+)?$')
const parseStringInput = text => ((!!text || text === 0) && digitsOnly.test(text)) ? parseFloat(text) : text;

// --------------------------------- Show ---------------------------------

export const ModuleShow = (props) => {
  const classes = useStyles();
  const [moduleType, setModuleType] = React.useState(null);
  React.useEffect(() => {
    if (props.id) {
      dataProvider.getOne('module', {id: props.id})
        .then((res) => {setModuleType(res.data.moduleMetadata)});
    }
  // eslint-disable-next-line
  }, []);
  return (
    <Show {...props} actions={<CustomShowActions/>}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="_id" label="Name"/>
          <TextField source="name" label="Display name"/>
          <TextField source="moduleMetadata" label="Module Metadata"/>
          <TextField source="cloudService" label="Cloud Service (generic service)"/>
          <TextField source="audioDirection" label="Audio Direction"/>
          <TextField source="lang" label="Language" />
          <TextField source="offer" label="Offer"/>
          <ImageField source="thumbnail" className={classes.imgContainer}/>
          <UrlField source="thumbnail" label="Thumbnail url"/>
          <ImageField source="icon" title="icon" className={classes.imgContainer}/>
          <UrlField source="icon" label="Icon url"/>
          <TextField source="color" label="Color" />
          <TextField source="defaultPriority" label="Default Priority"/>
          <TextField source="defaultActivated" label="Activated by default"/>
        </Tab>
        {moduleType === 'news' ? (
          <Tab label="Topics">
            <TopicList source="topics"/>
          </Tab>
        ) : null}
        {moduleType === 'newscasts' ? (
          <Tab label="Newscasts">
            <NewscastList source="submodules"/>
          </Tab>
        ): null}
        <Tab label="Setup">
          <SetupList source="setup" />
        </Tab>
        {moduleType === 'juiceServices' ? (
          <Tab label="Messages">
            <MessagesList source="messages"/>
          </Tab>
        ) : null}
      </TabbedShowLayout>
    </Show>
  );
};

class NewscastList extends React.Component {
  constructor() {
    super();
    this.state = {
      rows: [],
    };
  }

  async componentDidMount() {
    const rows = [];
    for (let newscastId of this.props.record[this.props.source]) {
      const response = await dataProvider.getOne('newscast', {id: newscastId});
      rows.push(response.data);
    }
    this.setState({rows: rows});
  }

  render() {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Module name</TableCell>
              <TableCell>Module feed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.name?.fr}</TableCell>
                <TableCell>{row.feed}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

};

const SetupList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  if (!record[source]) {
    return null;
  }
  const rows = record[source].map(elt => {
    const key = Object.keys(elt.param)[0];
    const value = elt.param[key];
    return ({key: key, value: value, changeable: elt.changeable})
  })
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Parameter</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Changeable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.key}>
              <TableCell>{row.key}</TableCell>
              <TableCell>{row.value}</TableCell>
              <TableCell>{row.changeable}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TopicList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  if (!record[source]) {
    return null;
  }
  // const rows = record[source].map(elt => ({...elt, key: elt}))
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Module name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record[source].map((row) => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MessagesList = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  if (!record[source]) {
    return null;
  }
  const messages = record[source];
  return (
    <>
    {messages.map((message, index) => {
      return (
        <Card fullWidth={true} className={classes.card}>
          <div>
            <h3>Message {index + 1}</h3>
            <Message message={message}/>
          </div>
        </Card>
      )
    })}
    </>
  )
}

const Message = ({message}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Param</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>

        </TableHead>
        <TableBody>
          {Object.keys(message).map((row) => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
              <TableCell>{JSON.stringify(message[row])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

// --------------------------------- Create ---------------------------------

export const ModuleCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="_id" label="Name" validate={[required()]} />
      <TextInput source="name.fr" label="Display name fr" />
      <TextInput source="name.en" label="Display name en" />
      <ReferenceInput label="Module Metadata" source="moduleMetadata" reference="moduleMetadata" sort={{order:'ASC'}}>
        <SelectInput optionText="_id" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput label="Audio Direction" source="audioDirection" reference="audioDirection" sort={{order:'ASC'}}>
        <SelectInput optionText="_id" validate={[required()]} />
      </ReferenceInput>
      <BooleanInput source="cloudService" label="Cloud Service (generic setup)" />
      <SelectInput source="lang" choices={[
        { id: 'fr', name: 'Francais' },
        { id: 'en', name: 'Anglais' },
      ]} validate={[required()]} />
      <SelectInput source="offer" choices={[
        { id: 'ad', name: 'Ad' },
        { id: 'freemium', name: 'Freemium' },
        { id: 'premium', name: 'Premium' },
        { id: 'admin', name: 'Admin' },
        { id: 'iad', name: 'IAD' },
        { id: 'macif', name: 'MACIF' },
      ]} validate={[required()]} />
      <TextInput source="thumbnail" label="Thumbnail" fullWidth={true} validate={[required()]} />
      <TextInput source="color" label="Color" validate={[required()]} />
      <NumberInput label="Default Priority" source='defaultPriority' min={0} max={1} step={0.01} validate={[required()]}/>
    </SimpleForm>
  </Create>
);


const useStyles = makeStyles()((theme) => ({
  imgContainer: {
    '& img': {
      height: 50,
      width: 50,
      objectFit: "contain"
    }
  },
  card: {
    elevation: 3,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
  },
  inputColumns: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  inputColumn: {
    marginRight: 10,
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    // flexDirection: 'row',
  },
  booleanInout: {
    marginLeft: 10,
  },
}));
